*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-family: Rubik, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

:root, [data-theme] {
  background-color: hsla(var(--b1) / var(--tw-bg-opacity, 1) );
  color: hsla(var(--bc) / var(--tw-text-opacity, 1) );
}

html {
  -webkit-tap-highlight-color: transparent;
}

:root {
  --p: 189 42% 33%;
  --pf: 189 42% 27%;
  --sf: 201 100% 56%;
  --af: 0 86% 47%;
  --nf: 189 42% 27%;
  --b2: 213 15% 11%;
  --b3: 213 15% 10%;
  --bc: 213 22% 82%;
  --pc: 189 100% 87%;
  --sc: 201 100% 14%;
  --ac: 0 100% 92%;
  --nc: 189 100% 87%;
  --inc: 198 100% 12%;
  --suc: 158 100% 10%;
  --wac: 43 100% 11%;
  --erc: 0 100% 14%;
  --rounded-box: 1rem;
  --rounded-btn: .5rem;
  --rounded-badge: 1.9rem;
  --animation-btn: .25s;
  --animation-input: .2s;
  --btn-text-case: uppercase;
  --btn-focus-scale: .95;
  --border-btn: 1px;
  --tab-border: 1px;
  --tab-radius: .5rem;
  --s: 201 100% 70%;
  --a: 0 86% 59%;
  --n: 189 42% 33%;
  --b1: 213 15% 12%;
  --in: 198 93% 60%;
  --su: 158 64% 52%;
  --wa: 43 96% 56%;
  --er: 0 91% 71%;
}

*, :before, :after {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.container {
  width: 100%;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.input {
  height: 3rem;
  border-width: 1px;
  border-color: hsl(var(--bc) / var(--tw-border-opacity) );
  --tw-border-opacity: 0;
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b1) / var(--tw-bg-opacity) );
  border-radius: var(--rounded-btn, .5rem);
  flex-shrink: 1;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: .875rem;
  line-height: 2;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.input-group > *, .input-group > .input {
  border-radius: 0;
}

.link {
  cursor: pointer;
  text-decoration-line: underline;
}

.menu > :where(li.disabled > :not(ul):focus) {
  cursor: auto;
}

.range {
  height: 1.5rem;
  width: 100%;
  cursor: pointer;
  -webkit-appearance: none;
  --range-shdw: var(--bc);
  border-radius: var(--rounded-box, 1rem);
  background-color: #0000;
  overflow: hidden;
}

.range:focus {
  outline: none;
}

.stack {
  place-items: flex-end center;
  display: inline-grid;
}

.stack > * {
  z-index: 1;
  width: 100%;
  opacity: .6;
  grid-row-start: 1;
  grid-column-start: 1;
  transform: translateY(1rem)scale(.9);
}

.stack > :nth-child(2) {
  z-index: 2;
  opacity: .8;
  transform: translateY(.5rem)scale(.95);
}

.stack > :nth-child(1) {
  z-index: 3;
  opacity: 1;
  transform: translateY(0)scale(1);
}

.steps .step {
  text-align: center;
  min-width: 4rem;
  grid-template-rows: 40px 1fr;
  grid-template-columns: auto;
  place-items: center;
  display: grid;
}

.swap {
  user-select: none;
  cursor: pointer;
  place-content: center;
  display: inline-grid;
  position: relative;
}

.swap > * {
  grid-row-start: 1;
  grid-column-start: 1;
  transition-property: transform, opacity;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.swap input {
  appearance: none;
}

.swap .swap-on, .swap .swap-indeterminate, .swap input:indeterminate ~ .swap-on, .swap input:checked ~ .swap-off, .swap.swap-active .swap-off, .swap input:indeterminate ~ .swap-off {
  opacity: 0;
}

.swap input:checked ~ .swap-on, .swap-active .swap-on, .swap input:indeterminate ~ .swap-indeterminate {
  opacity: 1;
}

.table {
  text-align: left;
  position: relative;
}

.\!table {
  text-align: left !important;
  position: relative !important;
}

.table th:first-child {
  z-index: 11;
  position: sticky;
  left: 0;
}

.\!table th:first-child {
  z-index: 11 !important;
  position: sticky !important;
  left: 0 !important;
}

@keyframes button-pop {
  0% {
    transform: scale(var(--btn-focus-scale, .95) );
  }

  40% {
    transform: scale(1.02);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes checkmark {
  0% {
    background-position-y: 5px;
  }

  50% {
    background-position-y: -2px;
  }

  100% {
    background-position-y: 0;
  }
}

.input[list]::-webkit-calendar-picker-indicator {
  line-height: 1em;
}

.input:focus {
  outline: 2px solid hsla(var(--bc) / .2);
  outline-offset: 2px;
}

.input-disabled, .input[disabled] {
  cursor: not-allowed;
  --tw-border-opacity: 1;
  border-color: hsl(var(--b2, var(--b1)) / var(--tw-border-opacity) );
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b2, var(--b1)) / var(--tw-bg-opacity) );
  --tw-text-opacity: .2;
}

.input-disabled::placeholder, .input[disabled]::placeholder {
  color: hsl(var(--bc) / var(--tw-placeholder-opacity) );
  --tw-placeholder-opacity: .2;
}

.link:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.link:focus-visible {
  outline-offset: 2px;
  outline: 2px solid;
}

.menu :where(li:not(.menu-title):not(:empty)) > :where(:not(ul).active), .menu :where(li:not(.menu-title):not(:empty)) > :where(:not(ul):active) {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--p) / var(--tw-bg-opacity) );
  --tw-text-opacity: 1;
  color: hsl(var(--pc) / var(--tw-text-opacity) );
}

.menu li.disabled > * {
  user-select: none;
  color: hsl(var(--bc) / var(--tw-text-opacity) );
  --tw-text-opacity: .2;
}

.menu li.disabled > :hover {
  background-color: #0000;
}

@keyframes progress-loading {
  50% {
    left: 107%;
  }
}

@keyframes radiomark {
  0% {
    box-shadow: 0 0 0 12px hsl(var(--b1) ) inset, 0 0 0 12px hsl(var(--b1) ) inset;
  }

  50% {
    box-shadow: 0 0 0 3px hsl(var(--b1) ) inset, 0 0 0 3px hsl(var(--b1) ) inset;
  }

  100% {
    box-shadow: 0 0 0 4px hsl(var(--b1) ) inset, 0 0 0 4px hsl(var(--b1) ) inset;
  }
}

.range:focus-visible::-webkit-slider-thumb {
  --focus-shadow: 0 0 0 6px hsl(var(--b1) ) inset, 0 0 0 2rem hsl(var(--range-shdw) ) inset;
}

.range:focus-visible::-moz-range-thumb {
  --focus-shadow: 0 0 0 6px hsl(var(--b1) ) inset, 0 0 0 2rem hsl(var(--range-shdw) ) inset;
}

.range::-webkit-slider-runnable-track {
  height: .5rem;
  width: 100%;
  border-radius: var(--rounded-box, 1rem);
  background-color: hsla(var(--bc) / .1);
}

.range::-moz-range-track {
  height: .5rem;
  width: 100%;
  border-radius: var(--rounded-box, 1rem);
  background-color: hsla(var(--bc) / .1);
}

.range::-webkit-slider-thumb {
  background-color: hsl(var(--b1) );
  height: 1.5rem;
  width: 1.5rem;
  border-radius: var(--rounded-box, 1rem);
  -webkit-appearance: none;
  color: hsl(var(--range-shdw) );
  --filler-size: 100rem;
  --filler-offset: .6rem;
  box-shadow: 0 0 0 3px hsl(var(--range-shdw) ) inset, var(--focus-shadow, 0 0), calc(var(--filler-size) * -1 - var(--filler-offset) ) 0 0 var(--filler-size);
  border-style: none;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.range::-moz-range-thumb {
  background-color: hsl(var(--b1) );
  height: 1.5rem;
  width: 1.5rem;
  border-radius: var(--rounded-box, 1rem);
  color: hsl(var(--range-shdw) );
  --filler-size: 100rem;
  --filler-offset: .5rem;
  box-shadow: 0 0 0 3px hsl(var(--range-shdw) ) inset, var(--focus-shadow, 0 0), calc(var(--filler-size) * -1 - var(--filler-offset) ) 0 0 var(--filler-size);
  border-style: none;
  position: relative;
  top: 50%;
}

.range-accent {
  --range-shdw: var(--a);
}

@keyframes rating-pop {
  0% {
    transform: translateY(-.125em);
  }

  40% {
    transform: translateY(-.125em);
  }

  100% {
    transform: translateY(0);
  }
}

.steps .step:before {
  height: .5rem;
  width: 100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b3, var(--b2)) / var(--tw-bg-opacity) );
  --tw-text-opacity: 1;
  color: hsl(var(--bc) / var(--tw-text-opacity) );
  content: "";
  grid-row-start: 1;
  grid-column-start: 1;
  margin-left: -100%;
  top: 0;
}

.steps .step:after {
  content: counter(step);
  counter-increment: step;
  z-index: 1;
  height: 2rem;
  width: 2rem;
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b3, var(--b2)) / var(--tw-bg-opacity) );
  --tw-text-opacity: 1;
  color: hsl(var(--bc) / var(--tw-text-opacity) );
  border-radius: 9999px;
  grid-row-start: 1;
  grid-column-start: 1;
  place-self: center;
  place-items: center;
  display: grid;
  position: relative;
}

.steps .step:first-child:before {
  content: none;
}

.steps .step[data-content]:after {
  content: attr(data-content);
}

.table :where(th, td) {
  white-space: nowrap;
  vertical-align: middle;
  padding: 1rem;
}

.\!table :where(th, td) {
  white-space: nowrap !important;
  vertical-align: middle !important;
  padding: 1rem !important;
}

.table tr.active th, .table tr.active td, .table tr.active:nth-child(2n) th, .table tr.active:nth-child(2n) td {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b3, var(--b2)) / var(--tw-bg-opacity) );
}

.\!table tr.active th, .\!table tr.active td, .\!table tr.active:nth-child(2n) th, .\!table tr.active:nth-child(2n) td {
  --tw-bg-opacity: 1 !important;
  background-color: hsl(var(--b3, var(--b2)) / var(--tw-bg-opacity) ) !important;
}

.table tr.hover:hover th, .table tr.hover:hover td, .table tr.hover:nth-child(2n):hover th, .table tr.hover:nth-child(2n):hover td {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b3, var(--b2)) / var(--tw-bg-opacity) );
}

.\!table tr.hover:hover th, .\!table tr.hover:hover td, .\!table tr.hover:nth-child(2n):hover th, .\!table tr.hover:nth-child(2n):hover td {
  --tw-bg-opacity: 1 !important;
  background-color: hsl(var(--b3, var(--b2)) / var(--tw-bg-opacity) ) !important;
}

.table:where(:not(.table-zebra)) :where(thead, tbody, tfoot) :where(tr:not(:last-child) :where(th, td)) {
  --tw-border-opacity: 1;
  border-bottom-width: 1px;
  border-color: hsl(var(--b2, var(--b1)) / var(--tw-border-opacity) );
}

.\!table:where(:not(.table-zebra)) :where(thead, tbody, tfoot) :where(tr:not(:last-child) :where(th, td)) {
  --tw-border-opacity: 1 !important;
  border-bottom-width: 1px !important;
  border-color: hsl(var(--b2, var(--b1)) / var(--tw-border-opacity) ) !important;
}

.table :where(thead, tfoot) :where(th, td) {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b2, var(--b1)) / var(--tw-bg-opacity) );
  text-transform: uppercase;
  font-size: .75rem;
  font-weight: 700;
  line-height: 1rem;
}

.\!table :where(thead, tfoot) :where(th, td) {
  --tw-bg-opacity: 1 !important;
  background-color: hsl(var(--b2, var(--b1)) / var(--tw-bg-opacity) ) !important;
  text-transform: uppercase !important;
  font-size: .75rem !important;
  font-weight: 700 !important;
  line-height: 1rem !important;
}

.table :where(:first-child) :where(:first-child) :where(th, td):first-child {
  border-top-left-radius: .5rem;
}

.\!table :where(:first-child) :where(:first-child) :where(th, td):first-child {
  border-top-left-radius: .5rem !important;
}

.table :where(:first-child) :where(:first-child) :where(th, td):last-child {
  border-top-right-radius: .5rem;
}

.\!table :where(:first-child) :where(:first-child) :where(th, td):last-child {
  border-top-right-radius: .5rem !important;
}

.table :where(:last-child) :where(:last-child) :where(th, td):first-child {
  border-bottom-left-radius: .5rem;
}

.\!table :where(:last-child) :where(:last-child) :where(th, td):first-child {
  border-bottom-left-radius: .5rem !important;
}

.table :where(:last-child) :where(:last-child) :where(th, td):last-child {
  border-bottom-right-radius: .5rem;
}

.\!table :where(:last-child) :where(:last-child) :where(th, td):last-child {
  border-bottom-right-radius: .5rem !important;
}

.table :where(tbody th, tbody td) {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b1) / var(--tw-bg-opacity) );
}

.\!table :where(tbody th, tbody td) {
  --tw-bg-opacity: 1 !important;
  background-color: hsl(var(--b1) / var(--tw-bg-opacity) ) !important;
}

.steps-horizontal .step {
  text-align: center;
  grid-template-rows: repeat(2, minmax(0, 1fr));
  grid-template-columns: repeat(1, minmax(0, 1fr));
  place-items: center;
  display: grid;
}

.steps-vertical .step {
  grid-template-rows: repeat(1, minmax(0, 1fr));
  grid-template-columns: repeat(2, minmax(0, 1fr));
  display: grid;
}

.steps-horizontal .step {
  min-width: 4rem;
  grid-template-rows: 40px 1fr;
  grid-template-columns: auto;
}

.steps-horizontal .step:before {
  height: .5rem;
  width: 100%;
  --tw-translate-y: 0px;
  --tw-translate-x: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
  content: "";
  margin-left: -100%;
}

.steps-vertical .step {
  min-height: 4rem;
  grid-template-rows: auto;
  grid-template-columns: 40px 1fr;
  justify-items: start;
  gap: .5rem;
}

.steps-vertical .step:before {
  height: 100%;
  width: .5rem;
  --tw-translate-y: -50%;
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
  margin-left: 50%;
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.bottom-20 {
  bottom: 5rem;
}

.bottom-6 {
  bottom: 1.5rem;
}

.top-1\/2 {
  top: 50%;
}

.left-1\/2 {
  left: 50%;
}

.top-0 {
  top: 0;
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

.right-0 {
  right: 0;
}

.top-1\/3 {
  top: 33.3333%;
}

.m-0\.5 {
  margin: .125rem;
}

.m-0 {
  margin: 0;
}

.ml-4 {
  margin-left: 1rem;
}

.mr-2 {
  margin-right: .5rem;
}

.ml-1 {
  margin-left: .25rem;
}

.-mr-1 {
  margin-right: -.25rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mr-1 {
  margin-right: .25rem;
}

.mt-40 {
  margin-top: 10rem;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.table {
  display: table;
}

.\!table {
  display: table !important;
}

.hidden {
  display: none;
}

.h-6 {
  height: 1.5rem;
}

.h-screen {
  height: 100vh;
}

.h-20 {
  height: 5rem;
}

.w-full {
  width: 100%;
}

.w-\[5rem\] {
  width: 5rem;
}

.w-4 {
  width: 1rem;
}

.w-20 {
  width: 5rem;
}

.flex-1 {
  flex: 1;
}

.-translate-x-1\/2 {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.-translate-y-1\/2 {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.flex-row {
  flex-direction: row;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded {
  border-radius: .25rem;
}

.bg-gray-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity) );
}

.bg-secondary {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--s) / var(--tw-bg-opacity) );
}

.bg-primary {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--p) / var(--tw-bg-opacity) );
}

.bg-warning {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--wa) / var(--tw-bg-opacity) );
}

.bg-accent {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--a) / var(--tw-bg-opacity) );
}

.bg-base-100 {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b1) / var(--tw-bg-opacity) );
}

.bg-accent-focus {
  --tw-bg-opacity: 1;
  background-color: rgb(153 40 40 / var(--tw-bg-opacity) );
}

.bg-primary-focus {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--pf, var(--p)) / var(--tw-bg-opacity) );
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity) );
}

.bg-red-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(252 165 165 / var(--tw-bg-opacity) );
}

.p-4 {
  padding: 1rem;
}

.p-2 {
  padding: .5rem;
}

.pb-2 {
  padding-bottom: .5rem;
}

.pt-24 {
  padding-top: 6rem;
}

.text-center {
  text-align: center;
}

.font-bold {
  font-weight: 700;
}

.uppercase {
  text-transform: uppercase;
}

.tracking-widest {
  letter-spacing: .1em;
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity) );
}

.opacity-50 {
  opacity: .5;
}

.grayscale {
  --tw-grayscale: grayscale(100%);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.last\:mr-0:last-child {
  margin-right: 0;
}

:root {
  --table-height: 600px;
  --table-radius: 140px;
  --players-per-side: 3;
}

.table {
  width: 100%;
  --tw-bg-opacity: 1;
  background-color: hsl(var(--p) / var(--tw-bg-opacity) );
  height: var(--table-height);
  border-radius: var(--table-radius);
  --margin-h: 80px;
  transform: translateX(calc(-50% - var(--margin-h) ) );
  max-width: min(calc(100% - 2 * var(--margin-h) ), 300px);
  margin-left: var(--margin-h);
  margin-right: var(--margin-h);
  padding: 1rem;
  position: relative;
  left: 50%;
}

.table__player {
  text-align: center;
  display: inline-block;
  position: absolute;
  top: 0;
}

.table__player-name {
  --tw-translate-x: -50%;
  --tw-translate-y: 100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
  white-space: nowrap;
  max-width: 120px;
  text-overflow: ellipsis;
  position: absolute;
  bottom: 0;
  left: 50%;
  overflow: hidden;
}

.table__player-bet {
  white-space: nowrap;
  --tw-bg-opacity: 1;
  background-color: hsl(var(--pf, var(--p)) / var(--tw-bg-opacity) );
  border-radius: 9999px;
  padding: .25rem .75rem;
  font-size: .75rem;
  line-height: 1rem;
}

.table__player-stack {
  white-space: nowrap;
}

.table__player:nth-child(1) {
  --tw-translate-x: -50%;
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
  top: 0;
  left: 50%;
}

.table__player:nth-child(1) .table__player-stack {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
  position: absolute;
  top: -1.25rem;
  left: 50%;
}

.table__player:nth-child(1) .table__player-bet {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
  margin-top: 2rem;
  position: absolute;
  top: 100%;
  left: 50%;
}

.table__player:nth-child(2), .table__player:nth-child(3), .table__player:nth-child(4) {
  --tw-translate-x: 50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
  right: 0;
}

.table__player:nth-child(2) .table__player-stack, .table__player:nth-child(3) .table__player-stack, .table__player:nth-child(4) .table__player-stack {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
  position: absolute;
  top: 50%;
  left: 100%;
}

.table__player:nth-child(2) .table__player-bet {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
  position: absolute;
  top: 80%;
  right: 100%;
}

.table__player:nth-child(3) .table__player-bet, .table__player:nth-child(4) .table__player-bet {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
  position: absolute;
  top: 50%;
  right: 100%;
}

.table__player:nth-child(2), .table__player:nth-child(9) {
  top: calc( 1 * (var(--table-height) / (var(--players-per-side)  + 1) )  - var(--table-radius) / 1.4 );
}

.table__player:nth-child(3), .table__player:nth-child(8) {
  top: calc( 2 * (var(--table-height) / (var(--players-per-side)  + 1) )  - var(--table-radius) / 1.4 );
}

.table__player:nth-child(4), .table__player:nth-child(7) {
  top: calc( 3 * (var(--table-height) / (var(--players-per-side)  + 1) )  - var(--table-radius) / 1.4 );
}

.table__player:nth-child(5) {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
  right: 0;
  top: calc(100% - var(--table-radius) / 3);
}

.table__player:nth-child(5) .table__player-stack {
  position: absolute;
  bottom: .75rem;
  left: 100%;
}

.table__player:nth-child(5) .table__player-bet {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
  position: absolute;
  top: 0;
  right: 80%;
}

.table__player:nth-child(6) {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
  left: 0;
  top: calc(100% - var(--table-radius) / 3);
}

.table__player:nth-child(6) .table__player-stack {
  position: absolute;
  bottom: .75rem;
  right: 100%;
}

.table__player:nth-child(6) .table__player-bet {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
  position: absolute;
  top: 0;
  left: 80%;
}

.table__player:nth-child(7), .table__player:nth-child(8), .table__player:nth-child(9) {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
  left: 0;
}

.table__player:nth-child(7) .table__player-stack, .table__player:nth-child(8) .table__player-stack, .table__player:nth-child(9) .table__player-stack {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
  position: absolute;
  top: 50%;
  right: 100%;
}

.table__player:nth-child(9) .table__player-bet {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
  position: absolute;
  top: 80%;
  left: 100%;
}

.table__player:nth-child(7) .table__player-bet, .table__player:nth-child(8) .table__player-bet {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
  position: absolute;
  top: 50%;
  left: 100%;
}

.table__player-button {
  --border-size: 2px;
  margin-top: -var(--border-size);
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 100%;
  font-size: .8rem;
  font-weight: bold;
  line-height: 1.5rem;
  position: absolute;
  bottom: 0;
  right: 0;
  box-shadow: 2px 2px #0000001a;
}

.table__player-button + .table__player-button {
  right: -1rem;
}

.table__player-button--bb {
  color: #000;
  background-color: #e3c000;
}

.table__player-button--sb {
  color: #fff;
  background-color: #36245f;
}

.table__player-button--dealer {
  color: #000;
  background-color: #fff;
}

@keyframes flip-card {
  0% {
    opacity: 0;
    transform: translateY(-180px)rotate(80deg);
  }

  1% {
    opacity: 1;
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.rotate-in {
  animation: flip-card .5s forwards;
}

.rotate-in--delay-1 {
  opacity: 0;
  animation-delay: .1s;
}

.rotate-in--delay-2 {
  opacity: 0;
  animation-delay: .15s;
}

:root {
  --chip-size: 1rem;
  --chip-size-neg: calc(var(--chip-size) * -1);
}

.chip {
  vertical-align: middle;
  --border-size: 2px;
  margin-top: -var(--border-size);
  width: var(--chip-size);
  height: var(--chip-size);
  border: var(--border-size) white dotted;
  border-radius: 100%;
  display: inline-block;
  position: relative;
  box-shadow: 2px 2px #0000001a;
}

.chip, .chip--10 {
  background-color: #dc143c;
}

.chip--1 {
  background-color: #444;
}

.chip--5 {
  background-color: #daa520;
}

.chip--x + .chip--x {
  margin-right: -10px;
  left: -10px;
}

.chip--y {
  --index-y: 0;
}

.chip--y + .chip--y:nth-child(2) {
  --index-y: 1;
}

.chip--y + .chip--y:nth-child(3) {
  --index-y: 2;
}

.chip--y + .chip--y:nth-child(4) {
  --index-y: 3;
}

.chip--y + .chip--y:nth-child(5) {
  --index-y: 4;
}

.chip--y + .chip--y:nth-child(6) {
  --index-y: 5;
}

.chip--y + .chip--y:nth-child(7) {
  --index-y: 6;
}

.chip--y + .chip--y:nth-child(8) {
  --index-y: 7;
}

.chip--y + .chip--y:nth-child(9) {
  --index-y: 8;
}

.chip--y + .chip--y:nth-child(10) {
  --index-y: 9;
}

.chip--y + .chip--y:nth-child(11) {
  --index-y: 10;
}

.chip--y + .chip--y:nth-child(12) {
  --index-y: 11;
}

.chip--y + .chip--y:nth-child(13) {
  --index-y: 12;
}

.chip--y + .chip--y:nth-child(14) {
  --index-y: 13;
}

.chip--y + .chip--y:nth-child(15) {
  --index-y: 14;
}

.chip--y + .chip--y:nth-child(16) {
  --index-y: 15;
}

.chip--y + .chip--y:nth-child(17) {
  --index-y: 16;
}

.chip--y + .chip--y:nth-child(18) {
  --index-y: 17;
}

.chip--y + .chip--y:nth-child(19) {
  --index-y: 18;
}

.chip--y + .chip--y:nth-child(20) {
  --index-y: 19;
}

.chip--y + .chip--y:nth-child(20) ~ .chip--y {
  display: none;
}

.chip--y + .chip--y {
  left: var(--chip-size-neg);
  margin-right: var(--chip-size-neg);
  margin-top: calc(-10px * var(--index-y) );
}

.chip--stack:nth-child(1) + .chip--stack:nth-child(2) {
  left: var(--chip-size-neg);
  margin-right: var(--chip-size-neg);
  margin-top: -10px;
}

.chip--stack:nth-child(2) + .chip--stack:nth-child(3) {
  left: var(--chip-size-neg);
  margin-right: var(--chip-size-neg);
  margin-top: -20px;
}

.chip--stack:nth-child(3) + .chip--stack:nth-child(4) {
  left: var(--chip-size-neg);
  margin-right: var(--chip-size-neg);
  margin-top: -30px;
}

.chip--stack:nth-child(4) + .chip--stack:nth-child(5) {
  left: var(--chip-size-neg);
  margin-right: var(--chip-size-neg);
  margin-top: -40px;
}

.chip--stack:nth-child(5) + .chip--stack:nth-child(6) {
  left: var(--chip-size-neg);
  margin-right: var(--chip-size-neg);
  margin-top: -50px;
}

.chip--stack:nth-child(7) + .chip--stack:nth-child(8) {
  left: var(--chip-size-neg);
  margin-right: var(--chip-size-neg);
  margin-top: -10px;
}

.chip--stack:nth-child(8) + .chip--stack:nth-child(9) {
  left: var(--chip-size-neg);
  margin-right: var(--chip-size-neg);
  margin-top: -20px;
}

.chip--stack:nth-child(9) + .chip--stack:nth-child(10) {
  left: var(--chip-size-neg);
  margin-right: var(--chip-size-neg);
  margin-top: -30px;
}

.chip--stack:nth-child(10) + .chip--stack:nth-child(11) {
  left: var(--chip-size-neg);
  margin-right: var(--chip-size-neg);
  margin-top: -40px;
}

.chip--stack:nth-child(11) + .chip--stack:nth-child(12) {
  left: var(--chip-size-neg);
  margin-right: var(--chip-size-neg);
  margin-top: -50px;
}

.chip--stack:nth-child(13) + .chip--stack:nth-child(14) {
  left: var(--chip-size-neg);
  margin-right: var(--chip-size-neg);
  margin-top: -10px;
}

.chip--stack:nth-child(14) + .chip--stack:nth-child(15) {
  left: var(--chip-size-neg);
  margin-right: var(--chip-size-neg);
  margin-top: -20px;
}

.chip--stack:nth-child(15) + .chip--stack:nth-child(16) {
  left: var(--chip-size-neg);
  margin-right: var(--chip-size-neg);
  margin-top: -30px;
}

.chip--stack:nth-child(16) + .chip--stack:nth-child(17) {
  left: var(--chip-size-neg);
  margin-right: var(--chip-size-neg);
  margin-top: -40px;
}

.chip--stack:nth-child(17) + .chip--stack:nth-child(18) {
  left: var(--chip-size-neg);
  margin-right: var(--chip-size-neg);
  margin-top: -50px;
}

.chip--stack:nth-child(19) + .chip--stack:nth-child(20) {
  left: var(--chip-size-neg);
  margin-right: var(--chip-size-neg);
  margin-top: -10px;
}

.chip--stack:nth-child(20) + .chip--stack:nth-child(21) {
  left: var(--chip-size-neg);
  margin-right: var(--chip-size-neg);
  margin-top: -20px;
}

.chip--stack:nth-child(21) + .chip--stack:nth-child(22) {
  left: var(--chip-size-neg);
  margin-right: var(--chip-size-neg);
  margin-top: -30px;
}

.chip--stack:nth-child(22) + .chip--stack:nth-child(23) {
  left: var(--chip-size-neg);
  margin-right: var(--chip-size-neg);
  margin-top: -40px;
}

.chip--stack:nth-child(23) + .chip--stack:nth-child(24) {
  left: var(--chip-size-neg);
  margin-right: var(--chip-size-neg);
  margin-top: -50px;
}

.chip--stack:nth-child(25) + .chip--stack:nth-child(26) {
  left: var(--chip-size-neg);
  margin-right: var(--chip-size-neg);
  margin-top: -10px;
}

.chip--stack:nth-child(26) + .chip--stack:nth-child(27) {
  left: var(--chip-size-neg);
  margin-right: var(--chip-size-neg);
  margin-top: -20px;
}

.chip--stack:nth-child(27) + .chip--stack:nth-child(28) {
  left: var(--chip-size-neg);
  margin-right: var(--chip-size-neg);
  margin-top: -30px;
}

.chip--stack:nth-child(28) + .chip--stack:nth-child(29) {
  left: var(--chip-size-neg);
  margin-right: var(--chip-size-neg);
  margin-top: -40px;
}

.chip--stack:nth-child(29) + .chip--stack:nth-child(30) {
  left: var(--chip-size-neg);
  margin-right: var(--chip-size-neg);
  margin-top: -50px;
}

.chip--stack:nth-child(31) + .chip--stack:nth-child(32) {
  left: var(--chip-size-neg);
  margin-right: var(--chip-size-neg);
  margin-top: -10px;
}

.chip--stack:nth-child(32) + .chip--stack:nth-child(33) {
  left: var(--chip-size-neg);
  margin-right: var(--chip-size-neg);
  margin-top: -20px;
}

.chip--stack:nth-child(33) + .chip--stack:nth-child(34) {
  left: var(--chip-size-neg);
  margin-right: var(--chip-size-neg);
  margin-top: -30px;
}

.chip--stack:nth-child(34) + .chip--stack:nth-child(35) {
  left: var(--chip-size-neg);
  margin-right: var(--chip-size-neg);
  margin-top: -40px;
}

.chip--stack:nth-child(35) + .chip--stack:nth-child(36) {
  left: var(--chip-size-neg);
  margin-right: var(--chip-size-neg);
  margin-top: -50px;
}

.chip--stack:nth-child(37) + .chip--stack:nth-child(38) {
  left: var(--chip-size-neg);
  margin-right: var(--chip-size-neg);
  margin-top: -10px;
}

.chip--stack:nth-child(38) + .chip--stack:nth-child(39) {
  left: var(--chip-size-neg);
  margin-right: var(--chip-size-neg);
  margin-top: -20px;
}

.chip--stack:nth-child(39) + .chip--stack:nth-child(40) {
  left: var(--chip-size-neg);
  margin-right: var(--chip-size-neg);
  margin-top: -30px;
}

.chip--stack:nth-child(40) + .chip--stack:nth-child(41) {
  left: var(--chip-size-neg);
  margin-right: var(--chip-size-neg);
  margin-top: -40px;
}

.chip--stack:nth-child(41) + .chip--stack:nth-child(42) {
  left: var(--chip-size-neg);
  margin-right: var(--chip-size-neg);
  margin-top: -50px;
}

.chip--stack:nth-child(43) + .chip--stack:nth-child(44) {
  left: var(--chip-size-neg);
  margin-right: var(--chip-size-neg);
  margin-top: -10px;
}

.chip--stack:nth-child(44) + .chip--stack:nth-child(45) {
  left: var(--chip-size-neg);
  margin-right: var(--chip-size-neg);
  margin-top: -20px;
}

.chip--stack:nth-child(45) + .chip--stack:nth-child(46) {
  left: var(--chip-size-neg);
  margin-right: var(--chip-size-neg);
  margin-top: -30px;
}

.chip--stack:nth-child(46) + .chip--stack:nth-child(47) {
  left: var(--chip-size-neg);
  margin-right: var(--chip-size-neg);
  margin-top: -40px;
}

.chip--stack:nth-child(47) + .chip--stack:nth-child(48) {
  left: var(--chip-size-neg);
  margin-right: var(--chip-size-neg);
  margin-top: -50px;
}

.chip--stack:nth-child(48) ~ .chip--stack {
  display: none;
}



/*# sourceMappingURL=index.c52f1416.css.map */
